
<template>
       <section class="ama pt-5 pb-5"></section>
        <!-- Dashboard Section-->
        <section class="page-section Dashboard" id="Dashboard">
            <div class="container">
                <div class="row gx-5 d-flex justify-content-center">
                <!-- Dashboard Section Heading-->
                <h2 class="page-section-heading text-center text-uppercase text-secondary mb-0">Menu</h2>
                <!-- Icon Divider-->
                <div class="divider-custom">
                    <div class="divider-custom-line"></div> 
                </div>
                </div>
                <!-- Dashboard Grid Items-->
                <div class="row justify-content-center">
                    <!-- Dashboard Item 1-->
                    <div class="col-md-6 col-lg-3 mb-5 ">
                        <router-link to ="/listapregun">
                          <div class="Dashboard-item mx-auto w-100 d-flex justify-content-center">           
                              <div class=" Dashboard-item-caption d-flex align-items-center justify-content-center h-100 w-100">     
                              <i class="fas fa-question-circle icono text-white p-3"></i>  
                              </div>
                          </div>
                        </router-link>
                        <router-link to ="/listapregun" class="subr">
                        <h6 class="page-section-sub text-center text-uppercase text-secondary mb-0 pt-3">Preguntas</h6>
                        </router-link>
                        
                    </div>
                    <!-- Dashboard Item 2-->
                    <div class="col-md-6 col-lg-3 mb-5 ">
                        <router-link to ="/listavista">
                          <div class="Dashboard-item mx-auto w-100 d-flex justify-content-center">   
                              <div class=" Dashboard-item-caption d-flex align-items-center justify-content-center h-100 w-100">   
                              <i class="fab fa-forumbee icono text-white p-3"></i> 
                              </div>   
                          </div>
                        </router-link>
                        <router-link to ="/listavista" class="subr">
                        <h6 class="page-section-sub text-center text-uppercase text-secondary mb-0 pt-3">Avistamientos</h6>
                        </router-link>
                    </div>
                    <!-- Dashboard Item 3-->
                    <div class="col-md-6 col-lg-3 mb-5 ">
                         <router-link to ="/mapa">
                          <div class="Dashboard-item mx-auto w-100 d-flex justify-content-center">                         
                              <div class=" Dashboard-item-caption d-flex align-items-center justify-content-center h-100 w-100">                            
                              <i class="fas fa-map-marker-alt icono text-white p-3"></i>
                              </div>
                          </div>
                        </router-link>
                        <router-link to ="/mapa" class="subr">
                        <h6 class="page-section-sub text-center text-uppercase text-secondary mb-0 pt-3">Mapa</h6>
                         </router-link>
                    </div> 
                </div>
            </div>
            <!-- segunda fila -->
            <div class="container">
              <div class="row gx-5 d-flex justify-content-center">
                  <!-- Dashboard Item 4-->
                    <div class="col-md-6 col-lg-3 mb-5 ">
                        <router-link to ="/analitic">
                          <div class="Dashboard-item mx-auto w-100 d-flex justify-content-center">                         
                              <div class=" Dashboard-item-caption d-flex align-items-center justify-content-center h-100 w-100">                            
                                <i class="fas fa-chart-pie icono text-white p-3"></i>
                              </div>
                          </div>
                          </router-link>
                          <router-link to ="/analitic" class="subr">
                            <h6 class="page-section-sub text-center text-uppercase text-secondary mb-0 pt-3">Análisis</h6>
                          </router-link>
                    </div>
              </div>
            </div>
        </section>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    methods:{
        ...mapActions(['cerraSesion','cargarLocalStorage','listaavistamiento']),

    } , 
    computed:{
        ...mapGetters(['userAutenticado']),
    },
    created(){
    this.cargarLocalStorage()
    },

}
</script>

<style scoped>
.subr{

text-decoration: none;
}
.subr:hover{
  opacity: .8;
}
.ama{
    background: #FDBC55;
    color: white;
    height: 175px;
}
.page-section {
  padding: 4rem 0;
}
.page-section .page-section-heading {
  font-size: 2.25rem;
  line-height: 2rem;
}
.page-section-sub{
  font-size: 2rem;
}
@media (max-width: 992px) {
  .page-section .page-section-heading .page-section-sub {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
.Dashboard .Dashboard-item {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  height: 150px;
  cursor: pointer;
  position: relative;
  display: block;
  max-width: 150px;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #FDBC55;
}

.Dashboard-item-caption:hover {
  opacity: .8;
}
.Dashboard-modal .btn-close {
  color: #1abc9c;
  font-size: 2rem;
  padding: 1rem;
}
.Dashboard-modal .Dashboard-modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
}
@media (min-width: 992px) {
  .Dashboard-modal .Dashboard-modal-title {
    font-size: 3rem;
    line-height: 2.5rem;
  }
}
.divider-custom {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider-custom .divider-custom-line {
  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: #6c757d ;
  border-radius: 1rem;
  border-color: #6c757d ;
}
.divider-custom .divider-custom-line:first-child {
  margin-right: 1rem;
}
.divider-custom .divider-custom-line:last-child {
  margin-left: 1rem;
}
.divider-custom .divider-custom-icon {
  color: #6c757d ;
  font-size: 2rem;
}
.divider-custom.divider-light .divider-custom-line {
  background-color: #fff;
}
.divider-custom.divider-light .divider-custom-icon {
  color: #fff;
}
.icono{
  font-size: 100px;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
}

</style>