<template>
       <section class="ama">
            <div class="container px-5">
                <div class="row gx-5 d-flex justify-content-center">
                    <div class="col-lg-6  order-lg-1 ptop">
                        <div class="p-5">
                            <h2 class="display-4 text-center pt-5">Nuestros libros</h2>
                            <p class="text-center">Nos gusta compartir conocimiento</p>

                            <div class="featured-text-down mb-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 <div class="d-flex justify-content-center pt-5 ">
        <div class="mb-4 rounded-3 text-center d-flex flex-column justify-content-center w-40">
  
            <img class="marco imagen" src="../assets/image/laaventurademeli.jpg" alt="">
            
            <h1>La aventura de Meli</h1>
            <div class="featured-text mb-2"></div>
            <p class="m-3 pt">

              La aventura de Meli, narra la travesía de dos curiosas abejitas Meliponas quienes se encuentra perdidas en la ciudad de Mérida y desean regresar a casa. En esta historia encontrarás a interesantes personajes y descubrirás la aventura que vive una pequeña abeja.
            </p>

            <button type="button" class="btn align-self-center mb-3" v-on:click="Adquirir('La aventura de Meli')">Adquirir libro.</button>
   
        </div>

      </div>

      <div class="d-flex justify-content-center ">
        <div class="mb-4 rounded-3 text-center d-flex flex-column justify-content-center w-40">
  
            <img class="marco imagen" src="../assets/image/testimonios.jpg" alt="">
            
            <h1>A través de la memoria: Testimonios de Meliponicultores</h1>
            <div class="featured-text mb-2"></div>
            <p class="m-3 pt">
              Este libro describe las experiencias y aprendizajes que los meliponicultures han adquirido a través del tiempo sobre las abejas sin aguijón. Nos comparten cómo fue el acercamiento con estas especies y por qué siguen en el camino del arte de cuidar abejas.
            </p>

            <button type="button" class="btn align-self-center mb-3" v-on:click="Adquirir('A través de la memoria: Testimonios de Meliponicultores')">Adquirir libro.</button>
   
        </div>

      </div>
      <div class="d-flex justify-content-center ">
        <div class="mb-4 rounded-3 text-center d-flex flex-column justify-content-center w-40">
  
            <img class="marco imagen" src="../assets/image/abejas-meliponas-y-cuidados.jpeg" alt="">
            
            <h1>La vida de las abejas meliponas y sus cuidados</h1>
            <div class="featured-text mb-2"></div>
            <p class="m-3 pt">
              Este libro describe de manera amena y sencilla, con imágenes y fotografías inéditas, las principales características de las abejas sin aguijón presentes en la Península de Yucatán. Su lectura permite un acercamiento inicial para personas interesadas en las diferentes especies de meliponinos, así como sus cuidados, su manejo y preservación. Su meta es acercar a jóvenes y adultos al arte de cuidar abejas meliponas, un legado invaluable transmitido de generación en generación en las comunidades mayas. Finalmente, se hace énfasis en los retos que enfrenta la conservación de estas especies fundamentales para los ecosistemas del sur de México.
            </p>

            <!-- <button type="button" class="btn align-self-center mb-3" v-on:click="AdquirirG('La vida de las abejas meliponas y sus cuidados')" >Adquirir libro.</button> -->
   
            <a href="/La_Vida_de_las_Abejas_Meliponas.pdf" class="btn align-self-center mb-3" download="La Vida de las Abejas Meliponas.pdf">Descargar</a>
       
        </div>

      </div>
         <Footer />
</template>
<script>
import Footer from "../components/Footer.vue";

export default {
    components: {
      Footer
    },
    methods:{
        Adquirir(text){
        this.$swal({
         // icon: 'success',
          title: 'Adquiere '+text,
          text: 'Escríbenos a dmaganao@yahoo.com.mx para solicitar tu libro, no olvides poner tu nombre completo y el lugar de donde nos escribes.',
        }); 
        },
        AdquirirG(text){
        this.$swal({
         // icon: 'success',
          title: 'Adquiere '+text,
          text: 'Escríbenos a dmaganao@yahoo.com.mx para solicitar tu libro gratis, no olvides poner tu nombre completo y el lugar de donde nos escribes.',
        }); 
        },
    }
}
</script>

<style scoped>
.ama{
    background: #FDBC55;
    color: white;
}
.marco{
  border-radius: 50px 50px 50px 50px;   
}
.btn{
  background-color: #FDBC55;
  color: white;
}
.btn:hover{
  background-color: white;
  border-width: 1px;
  color:#FDBC55 ;
  border-style: solid;
  border-color:#fdbc55;
  
}
.w-40{
  width: 40%;
}
.btn:focus {
  box-shadow: 0 1rem 1rem rgba(253, 188, 85,.75);
}
.ptop{
    padding-top:3rem ;
}
.pt{
  color: #9e9e9e;
  font-size: 17px;
  text-align: justify;
}


.featured-text {
  padding: 2rem;
}
@media (min-width: 350px) {
  .featured-text{
    padding: 0 0 0 2rem;
    border-bottom: 0.5rem solid #fdbc55;
    margin-left: 20%;
    margin-right: 20%;
  }
}

.featured-text-down {
  padding: 2rem;
}
@media (min-width: 350px) {
  .featured-text-down {
    padding: 0 0 0 2rem;
    border-bottom: 0.5rem solid #ffffff;
    margin-left: 30%;
    margin-right: 30%;
  }
}
@media (max-width: 800px) {
  .pt{
    font-size: 15px;
  }
  .w-40{
    width: 60%;
  }
}
</style>