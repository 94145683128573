<template> 


  <div class="arri">
    <div>
      <div class="col-lg-8 w-100 pad "> 
        <h2 class="mb-3 d-flex justify-content-center">¿Que desea hacer?</h2>
          <div class="my-3">
            <div class="form-check d-flex justify-content-center p-2">
              <input id="Preguntas" name="SeleccionForm" type="radio" class="form-check-input" value="true" v-model="Seleccion" checked required>
              <label class="form-check-label" for="Preguntas">Preguntar tema de interes.</label>
            </div>
            <div class="form-check d-flex justify-content-center p-2">
              <input id="Avistamientos" name="SeleccionForm" type="radio" class="form-check-input" value="false" v-model="Seleccion" required>
              <label class="form-check-label" for="Avistamientos">Enviar avistamiento de colmena.</label>
            </div>
          </div>

      </div>  
    </div>
  <!-- {{Seleccion}}  -->
  <Formulario v-if="Seleccion=== 'false'" />
  <FormQues v-if="Seleccion!= 'false'"/>
</div>



<div id="footer">
   <!-- v-bind:style=" Seleccion==='true' ? 'position: absolute;' : 'position: relative;'" > -->
<Footer />
</div>



</template>

<script>
import Footer from "../components/Footer.vue"
import Formulario from "../components/Formulario.vue"
import FormQues from "../components/FormQues.vue"
export default {
  components: {
    Footer,Formulario,FormQues
  },
  data(){
   return{
     Seleccion : true,
     url: null,
      Captura:{
      nombres:'',
      apellidos:'',
      correo:'',
      numeroCon:'',
      imagen: null,
      numero:0,
    },
   }
  },
  methods:{

  },
 
  mounted(){
  }

}
</script>

<style scoped>
.arri{
border-top-width: 8rem;
border-top-style: solid;
border-block-start-color: #FDBC55;
padding-top: 50px;
}
#footer {
  margin-top: 50px;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 40px;
}
.pad{
    padding-left: 15%;
    padding-right: 15%;

}

</style>