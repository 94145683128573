
<template>
       <section class="ama">
            <div class="container px-5">
                <div class="row gx-5 d-flex justify-content-center">
                    <div class="col-lg-6  order-lg-1 ptop">
                        <div class="p-5">
                            <h2 class="display-4">¿Qué son las abejas?</h2>
                            <p>Las abejas son insectos que pueden ser solitarios o vivir en colmenas sociales. Tienen un importante papel en la naturaleza y en la comida ya que llevan el polen de flor a flor y eso permite la formación de semillas y frutos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-6 order-1 order-lg-2">
                         <div class="p-5"><img class="img-fluid imagen imgselec hexagon"  data-bs-toggle="modal" data-bs-target="#Modal1" src="../assets/InfGene/IMG_1.jpg" alt="..." /></div> 
                    </div>
                    <div class="col-lg-6  order-0 order-lg-1">
                        <div class="p-5">
                            <h2 class="display-4">¿Cuáles son las abejas Apis mellifera?</h2>
                            <p>Son abejas originarias de Europa que son buenas polinizadoras y grandes productoras de miel. Sus colonias son bastante grandes, hasta 30 000 mil abejas y poseen un veneno que puede utilizarse en medicina para tratar dolencias. Su panales suelen colgar de árboles o paredes, son exteriores con mucha población.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  
        <section>
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-6  order-1 order-lg-1">
                        <div class="p-5"><img class="img-fluid  imagen imgselec hexagon" data-bs-toggle="modal" data-bs-target="#Modal2" src="../assets/InfGene/IMG_2.jpg" alt="..." /></div>
                    </div>
                    <div class="col-lg-6 order-0 order-lg-2">
                        <div class="p-5 ">
                            <h2 class="display-4">¿Existen abejas que no pican?</h2>
                            <p>Las abejas nativas por procesos de evolución a lo largo de miles de años perdieron la capacidad de producir veneno y su aguijón está atrofiado. Son polinizadores de plantas nativas y su producción de miel es baja. Algunas especies, como Melipona beecheii son cuidadas por la cultura maya. Sus colmenas habitan en troncos huecos o en cajas artificiales, sus colmenas no están expuestas al aire libre.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-6  order-1 order-lg-2">
                        <div class="p-5"><img class="img-fluid imagen imgselec hexagon" data-bs-toggle="modal" data-bs-target="#Modal3" src="../assets/InfGene/IMG_3.jpg" alt="..." /></div>
                    </div>
                    <div class="col-lg-6 order-0 order-lg-1">
                        <div class="p-5">
                            <h2 class="display-4">¿Cuál es la diferencia entre una abeja y una mosca?</h2>
                            <p>Aunque algunas moscas pueden alimentarse de néctar de las flores como las abejas, no tienen un saco para colectar polen (curbícula) y por tanto su papel polinizador es bajo. Tampoco las moscas crean colonias sociales como las abejas. La mayoría de las moscas, sobre todo las domésticas, se alimentan mayormente de materia orgánica (basura) en descomposición, por tanto, pueden ser causa de enfermedades. Lo mejor es mantener los desechos orgánicos bien cerrados para evitar la presencia de moscas y enfermedades.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-6  order-1 order-lg-1">
                        <div class="p-5"><img class="img-fluid imagen imgselec hexagon" data-bs-toggle="modal" data-bs-target="#Modal4" src="../assets/InfGene/IMG_4.jpg" alt="..." /></div>
                    </div>
                    <div class="col-lg-6 order-0 order-lg-2">
                        <div class="p-5">
                            <h2 class="display-4">¿Existen abejas de color negro?</h2>
                            <p>Sí, existen especies de color oscuro como el género Trigona, que se caracterizan por además de su color por formar colmenas en el interior de las paredes y en los árboles vivos. Producen poca cantidad de miel y son excelentes polinizadoras de plantas nativas y hortalizas como el chile habanero y el tomate. Ninguna de estas abejas pica ni posee veneno.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-6 order-1 order-lg-2">
                        <div class="p-5"><img class="img-fluid imgselec hexagon  dos " data-bs-toggle="modal" data-bs-target="#Modal5" src="../assets/InfGene/IMG_5.jpg" alt="..." /> 
                        <img class="img-fluid imagen imgselec hexagon " data-bs-toggle="modal" data-bs-target="#Modal6" src="../assets/InfGene/IMG_6.jpg" alt="..." /></div>
                    </div>
                    <div class="col-lg-6  order-0 order-lg-1">
                        <div class="p-5">
                            <h2 class="display-4">¿Y las avispas? ¿cómo puedo diferenciar a las avispas de las abejas?</h2>
                            <p>Las avispas son insectos carnívoros, se alimentan de otros insectos, forman colmenas exteriores y tienen picaduras dolorosas como mecanismo de defensa. No producen miel como las abejas. La población en su colmenas inicial es mucho menor que las abejas. Tienen una unión de abdomen y tórax (cintura por decir así) muy delgado. No es recomendable perturbar el nido de las avispas, es mejor solicitar apoyo a las autoridades como los bomberos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Modals />
        <Footer />
</template>

<script>
import Footer from "../components/Footer.vue"
import Modals from "../components/Modals.vue"
export default {
  components: {
    Footer,Modals
  },
}
</script>

<style scoped>
.ama{
    background: #FDBC55;
    color: white;
}
.dos{
    width: 250px;
    height: 250px;
}
.hexagon {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);

}
.imagen{
    height: 500px;
    width: 500px;

}
@media(max-width: 768px) {
.imagen{
    height: 250px;
    width: 250px;

}
}
.imgselec:hover{
     opacity:.7;
     
}
.imgselec{
    cursor: pointer;
    
}
.ptop{
    padding-top:8rem ;
}
p{
    text-align: justify;
    font-size: 20px;
}
</style>