<template>
  <div id="login_abeja" class="bodylogin text-center">
<main class="form-signin">
  <form class="pt-5 " @submit.prevent="procesarformulario()">

      <div class="alert alert-danger" v-if="error !==null && error.error=='Unauthorized'">
        La contraseña o correo son incorrectos.
      </div>
      <div class="alert alert-danger" v-if="error !==null && error.error=='Usuario no autenticado.'">
        Sesion expirada.
      </div>
      <div v-if="log" class="alert alert-warning" role="alert">
        Iniciando sesión... 
      </div>
    <img class="mb-4" src="../assets/image/Laabejaguardiana.png" alt="" width="75" height="75">
    <h1 class="h3 mb-3 fw-normal text-white">Bienvenido</h1>

    <div class="form-floating">
      <input type="email" class="form-control" id="floatingInput"  v-model.trim="Captura.email" placeholder="name@example.com">
      <label for="floatingInput">Correo electrónico</label>
    </div>
    <div class="form-floating">
      <input type="password" class="form-control" id="floatingPassword" v-model.trim="Captura.password" placeholder="Password">
      <label for="floatingPassword">Contraseña</label>
    </div>
    <button class="w-100 btn btn-lg btn-ama" type="submit">Iniciar sesión</button>
    
    <!-- <p class="mt-5 mb-3 text-muted">&copy; 2017–2021</p> -->
  </form>
</main>
  </div>

</template>

<script>
import {mapActions,mapGetters,mapState} from 'vuex'
export default {
  data(){
    return{
      Captura:{
        email:'',
        password:''
      }
    }
  },

  computed:{
  ...mapState(['error','log']),
  },

  methods:{
    ...mapActions(['ingresoUsuario']),
     procesarformulario(){
       if(this.Captura.email && this.Captura.password){
         //console.log(this.Captura)
         this.ingresoUsuario(this.Captura)
         this.Captura.email=''        
         this.Captura.password=''
       }
     },


  },
     

}
</script>

<style scoped >

.btn-ama{
    background-color: #FDBC55;
    color: white;
}
.prueba{
    background-color: white;
    color: black;
}
.bodylogin {
  height: 100%;
  width: 100%;
  position: fixed;
}

.bodylogin {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background:linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 75%, #000 100%), url("../assets/image/IMG_0111.jpg") ;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>