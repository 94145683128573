
<template>
       <section class="ama pt-5 pb-5 d-flex justify-content-center w-100">
       </section>
        <h2 v-if="cargando">Cargando este proceso puede tomar algunos segundos.</h2>
        <h5 v-if="cargando">Espere a que desaparezca este mensaje.</h5>
        <h2 class="display-4 p-5">Preguntas</h2>
      <div class="overflow-auto">
          <table v-if="list" class="table table-striped table-hover">
          <thead>
            <tr>
              <th class="text-center" scope="col">Nombres</th>
              <th class="text-center" scope="col">Apellidos</th>
              <th class="text-center" scope="col">Correo</th>
              <th class="text-center" scope="col">NumeroCon</th>
              <th class="text-center" scope="col">Pregunta</th>
              <th class="text-center" scope="col">Respuesta</th>
              <th class="text-center" scope="col">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in preguntas" :key="item.id">
              <td class="text-center">{{item.nombres}}</td>
              <td class="text-center">{{item.apellidos}}</td>
              <td class="text-center">{{item.correo}}</td>
              <td class="text-center">{{item.numeroCon}}</td>
              <td class="text-center">{{item.Pregun}}</td>
              <td class="text-center">
                <p v-if="item.Respuesta !== null ">{{item.Respuesta}}</p>
                
                <button type="button" class="btn btn-warning m-1" v-if="item.Respuesta === null" 
                @click="responsemo(item)" data-bs-toggle="modal" data-bs-target="#ModalResponse">
                Responder
                </button>
              </td>
              <td>
              <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-danger m-1"  data-bs-toggle="modal" data-bs-target="#ModalEliminar"  @click="pregundelet(item.id)">Eliminar</button>
              </div>
              </td>
            </tr> 
          </tbody>
        </table>
    <!-- tabla skelton -->
          <table v-if="!list" class="table table-striped table-hover">
          <thead>
            <tr>
              <th class="text-center" scope="col">Nombres</th>
              <th class="text-center" scope="col">Apellidos</th>
              <th class="text-center" scope="col">Correo</th>
              <th class="text-center" scope="col">NumeroCon</th>
              <th class="text-center" scope="col">Pregunta</th>
              <th class="text-center" scope="col">Respuesta</th>
              <th class="text-center" scope="col">Acción</th>
            </tr>
          </thead>
          <tbody aria-hidden="true" class="placeholder-glow">
            <tr  v-for="(item,index) in skeleton" :key="index">
              <td class="text-center"><span class="placeholder col-12"></span></td>
              <td class="text-center"><span class="placeholder col-12"></span></td>
              <td class="text-center"><span class="placeholder col-12"></span></td>
              <td class="text-center"><span class="placeholder col-12"></span></td>
              <td class="text-center"><span class="placeholder col-12"></span></td>
              <td class="text-center">
                <span class="placeholder col-12"></span>
              </td>
              <td>
              <div class="d-flex justify-content-center">
                <a href="#" tabindex="-1"  class="btn btn-danger m-1  disabled placeholder col-6"></a>

              
              </div>
              </td>
            </tr> 
          </tbody>
        </table>
      </div>


            <!-- modal eliminar  -->
        <div class="modal fade" id="ModalEliminar" data-bs-backdrop="static" tabindex="-1" aria-labelledby="ModalEliminar" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                              <h1>¿Esta seguro de eliminar?</h1>
                                <div class="col-lg-8">
                                    <button class="btn btn-danger m-1" type="button" data-bs-dismiss="modal" aria-label="Close" @click="Seguroeliminar()">
                                        Eliminar.
                                    </button>
                                    <button class="btn btn-primary m-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        Cerrar ventana.
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <!-- modal responder  -->
        <div class="modal fade" id="ModalResponse"  tabindex="-1" aria-labelledby="ModalEliminar" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                              <h1 class="text-center">Responder pregunta</h1>
                               <h5 class="text-center">{{preg}}</h5>
                                    <div class="modal-body">
                                      <form>
                                        <div class="mb-3">
                                          <label for="message-text" class="col-form-label">Mensaje:</label>
                                          <textarea class="form-control" id="message-text" v-model="respue"></textarea>
                                        </div>
                                      </form>
                                    </div>
                                <div class="col-lg-8 d-flex justify-content-center">
                                    <button class="btn btn-warning m-1" type="button" data-bs-dismiss="modal" aria-label="Close" @click="responder()">
                                        Responder.
                                    </button>
                                    <button class="btn btn-primary m-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        Cerrar ventana.
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     <!-- paginacion -->
      <nav v-if="list"  aria-label="...">
        <ul class="pagination pagination-lg">
          <li class="page-item">
            <a class="page-link" @click="setCurrent(1)" href="#">inicio</a>
          </li>
          <template v-for="(page,index) in paginD" :key="index">
            <li
              :class="{ 'active page-item': page == current  }" class="page-item"
              @click="setCurrent(page)" 
            >
            <a :class="{ ' page-link active ': page == current  }" class="page-link" href="#" >{{ page }}</a> 
            </li>
          </template>
            <li class="page-item">
            <a class="page-link" @click="setCurrent(paginas)" href="#">final</a>
          </li>
        </ul>
      </nav> 
       <!-- {{preguntas}}  -->
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
  
    data(){
      return{
        cargando:true,
        idpregun:null,
        respue:'',
        pregunta:null,
        numShown: 3,
        total: null,
        current: 1,
        paginD:null,
        preg:null,
        preguntas:[],
        skeleton: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
        list:false,
      }
    },
    methods:{
        ...mapActions(['listapreg','cargarLocalStorage','deletepregunta','actualizarrespuesta']),
        pregundelet(idpr){
          this.idpregun=idpr
          
        },
        async Seguroeliminar(){
          let response = await this.deletepregunta(this.idpregun);
          if (response.status == 200) {
            this.$swal({
              icon: 'success',
              title: 'Eliminar',
              text: 'Pregunta eliminada.',
          });
        this.preguntas = this.preguntas.filter(item => item.id !== this.idpregun)
      }
        },
        responsemo(item){
          this.idpregun=item.id;
          this.respue=''
          this.preg= item.preg
          this.pregunta= item;
        },
        async responder(){
          this.pregunta.Respuesta=this.respue
          // console.log(this.pregunta)
          let response = await this.actualizarrespuesta(this.pregunta);
          
            if(response.status == 200){
              this.$swal({
              icon: 'success',
              title: 'Correo Enviado',
              text: 'El correo se envio con exito',
            });
            }
        },
        setCurrent(page){
          this.current=page 
          this.pages()
          this.preguntas_inpage(page);
      
        },
        pages(){
              
            const numShown = Math.min(this.numShown, this.total);
            let first = this.current - Math.floor(numShown / 2);
            first = Math.max(first, 1);
            first = Math.min(first, this.total - numShown + 1);
            //console.log(first)
            this.paginD= [...Array(numShown)].map((k,i) => i + first);
        },
        async preguntas_inpage(page) {
        var SOneDateFrom = JSON.parse(localStorage.getItem('usuario'));
        this.list = false;

        try {
          const res = await fetch(`http://127.0.0.1:8000/api/verpreguntas?page=${page}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + SOneDateFrom.access_token
            }
          })
          const dataDB = await res.json()
          console.log(dataDB.data);
          if (dataDB.error) {
            if (dataDB.error == 'Usuario no autenticado.') {
              router.push('/login')
              localStorage.removeItem('usuario')
            }
            return
          }
          // console.log(dataDB.data.data);
          this.preguntas = dataDB.data.data;
          this.list = true;

        } catch (error) {
          console.log(error)
        }
      },
    }, 
    async created(){
      this.cargarLocalStorage()
      let listapreeguntas=await this.listapreg();
      console.log(listapreeguntas.last_page);
      this.numShown= 3,
      this.total= listapreeguntas.last_page,
      this.current= 1,
      this.pages()
      this.preguntas = listapreeguntas.data;
      this.cargando=false;
      this.list = true;
    },


}
</script>

<style scoped>

.ama{
    background: #FDBC55;
    color: white;
    height: 175px;
}

/* .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #FDBC55;
} */
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
  color:#6c757d;
}

</style>