<template>
<!-- imagen_1 -->
          <div class="modal fade" id="Modal1" tabindex="-1" aria-labelledby="Modal1" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <!--Image-->
                                    <img class="img-fluid rounded mb-3" src="../assets/InfGene/IMG_1.jpg" alt="..." />
                                    <!--Text-->
                                    <p class="mb-4">Foto: Álvaro Carbajal, colmena artificial de Apis mellifera, tienen un abdomen más alargado y un aguijón funcional.</p>
                                    <button class="btn colorboton" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times fa-fw"></i>
                                        Cerrar ventana
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- imagen_2 -->
        <div class="modal fade" id="Modal2" tabindex="-1" aria-labelledby="Modal2" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <!--Image-->
                                    <img class="img-fluid rounded mb-3" src="../assets/InfGene/IMG_2.jpg" alt="..." />
                                    <!--Text-->
                                    <p class="mb-4">Foto: Álvaro Carbajal, colmena de Melipona beecheii en el interior de una caja tecnificada, su población de mil a tres mil abejas, su abdomen es más grueso y su aguijón no es funcional, no pican.</p>
                                    <button class="btn colorboton" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times fa-fw"></i>
                                        Cerrar ventana
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- imagen_3 -->
        <div class="modal fade" id="Modal3" tabindex="-1" aria-labelledby="Modal3" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <!--Image-->
                                    <img class="img-fluid rounded mb-3" src="../assets/InfGene/IMG_3.jpg" alt="..." />
                                    <!--Text-->
                                    <p class="mb-4">Foto: Diana Pérez, mosca alimentándose de néctar de las flores, las moscas carecen de la capacidad de colectar polen.</p>
                                    <button class="btn colorboton" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times fa-fw"></i>
                                        Cerrar ventana
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- imagen_4 -->
        <div class="modal fade" id="Modal4" tabindex="-1" aria-labelledby="Modal4" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <!--Image-->
                                    <img class="img-fluid rounded mb-3" src="../assets/InfGene/IMG_4.jpg" alt="..." />
                                    <!--Text-->
                                    <p class="mb-4">Foto: Marynes Martínez, abejas trigona atraídas por el olor de la pintura, ninguna de estas abejas pica y sólo algunas pueden tener comportamientos defensivos como enredarse en el cabello o dar pequeñas mordidas.</p>
                                    <button class="btn colorboton" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times fa-fw"></i>
                                        Cerrar ventana
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- imagen_5 -->
        <div class="modal fade" id="Modal5" tabindex="-1" aria-labelledby="Modal5" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <!--Image-->
                                    <img class="img-fluid rounded mb-3" src="../assets/InfGene/IMG_5.jpg" alt="..." />
                                    <!--Text-->
                                    <p class="mb-4">Foto: César Zaldívar, avispas del género Mischocyttarus, nótese que tienen una población menor a las abejas y tienen una colmena exterior, es importante no perturbarlas, ya que su veneno puede ser muy doloroso.</p>
                                    <button class="btn colorboton" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times fa-fw"></i>
                                        Cerrar ventana
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- imagen_6 -->
        <div class="modal fade" id="Modal6" tabindex="-1" aria-labelledby="Modal6" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div class="modal-body text-center pb-5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-8">
                                    <!--Image-->
                                    <img class="img-fluid rounded mb-3" src="../assets/InfGene/IMG_6.jpg" alt="..." />
                                    <!--Text-->
                                    <p class="mb-4">Foto: Cinthya Acevedo, avispas del género Polybia, nótese que tienen una población menor a las abejas y tienen una colmena exterior, es importante no perturbarlas, ya que su veneno puede ser muy doloroso.</p>
                                    <button class="btn colorboton" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times fa-fw"></i>
                                        Cerrar ventana
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.colorboton{
 background:#FDBC55;
 color: white;
}
</style>