<template>
  <div>
    <main>
      <div>
        <div class="col-lg-8 w-100 pad">
          <h4 class="mb-3">Apoya a las abejas.</h4>
          <form
            v-bind:class="{
              'needs-validation was-validated': valida,
              'needs-validation': !valida,
            }"
            @submit.prevent="procesarformulario()"
            novalidate
          >
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="firstName" class="form-label">Nombres</label>
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  placeholder=""
                  v-on:keypress="isLetter($event)"
                  v-model.trim="Captura.nombres"
                  required
                />
                <div class="invalid-feedback">Se requiere nombres.</div>
              </div>

              <div class="col-sm-6">
                <label for="lastName" class="form-label">Apellidos</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  placeholder=""
                  v-on:keypress="isLetter($event)"
                  v-model.trim="Captura.apellidos"
                  required
                />
                <div class="invalid-feedback">Se requiere apellidos.</div>
              </div>

              <div class="col-12">
                <label for="email" class="form-label">Correo de contacto</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="uncorreo@gmail.com"
                  v-model.trim="Captura.correo"
                  required
                />
                <div class="invalid-feedback">
                  Por favor introdusco un correo electrónico valido.
                </div>
              </div>
              <div class="col-12">
                <label for="email" class="form-label"
                  >Numero de contacto.(Opcional)</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  placeholder="9999999999"
                  v-model.trim="Captura.numeroCon"
                  v-on:keypress="isNumber($event)"
                />
              </div>
              <div class="col-12">
                <label for="email" class="form-label"
                  >¿Cual es su pregunta?</label
                >
                <textarea
                  class="form-control"
                  aria-label="With textarea"
                  v-model.trim="Captura.Pregun"
                  required
                ></textarea>
              </div>
            </div>
            <hr class="my-4" />
            <div class="d-flex justify-content-center">
              <button class="btn btn-ama" type="submit">Enviar</button>
            </div>
          </form>
        </div>
      </div>
    </main>
    {{ Seleccion }}
    <!-- {{imagen}}  -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      valida: false,
      Seleccion: null,
      url: null,
      Captura: {
        nombres: "",
        apellidos: "",
        correo: "",
        numeroCon: "",
        Pregun: "",
      },
    };
  },
  methods: {
    ...mapActions(["enviopreguntas"]),
    async procesarformulario() {
      if (
        this.Captura.apellidos &&
        this.Captura.nombres &&
        this.Captura.correo &&
        this.Captura.Pregun
      ) {
        this.$swal({
          icon: "success",
          title: "Enviando pregunta espere un momento.",
        });

        if (this.Captura.nombres === "" || this.Captura.apellidos === "") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Es necesario informacion de contacto.",
          });
          return;
        }

        let res = await this.enviopreguntas(this.Captura);
        let data = await res.json();

        if (data.status == "400") {
          this.$swal({
            icon: "error",
            title: "Ah ocurrido un problema",
            text: data.msg,
          });
          return;
        } else if (data.status == "200") {
          this.Captura.nombres = "";
          this.Captura.apellidos = "";
          this.Captura.correo = "";
          this.Captura.numeroCon = "";
          this.Captura.Pregun = "";
          this.valida = false;
          this.$swal({
            icon: "success",
            title: "Pregunta enviada",
            text: "Contestaremos a su pregunta lo mas pronto posible.",
          });
        }
      } else {
        this.valida = true;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z\s\u00C0-\u017F]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9\s-]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
  },

  mounted() {},
};
</script>

<style scoped>
.btn-ama {
  background-color: #fdbc55;
  color: white;
}
.previ {
  width: 125px;
  height: 120px;
  border: solid;
}
.pad {
  padding-left: 15%;
  padding-right: 15%;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>