<template>
  <div >
  <main>
    <div >
      <div class="col-lg-8 w-100 pad "> 
        <h4 class="mb-3">Apoya a las abejas.</h4>
        <form v-bind:class="{'needs-validation was-validated':valida,'needs-validation': !valida,}" @submit.prevent="procesarformulario()" novalidate>
          
          <div class="row g-3">
            <div class="col-sm-6">
              <label for="firstName" class="form-label" >Nombres</label>
              <input type="text" class="form-control" id="firstName" placeholder=""  v-on:keypress="isLetter($event)" v-model.trim="Captura.nombres" required>
              <div class="invalid-feedback">
                Se requiere nombres.
              </div>
            </div>

            <div class="col-sm-6">
              <label for="lastName" class="form-label">Apellidos</label>
              <input type="text" class="form-control" id="lastName" placeholder="" v-on:keypress="isLetter($event)" v-model.trim="Captura.apellidos" required>
              <div class="invalid-feedback">
                Se requiere apellidos.
              </div>
            </div>

            <div class="col-12">
              <label for="email" class="form-label">Correo de contacto</label>
              <input type="email" class="form-control" id="email" placeholder="uncorreo@gmail.com" v-model.trim="Captura.correo" required>
              <div class="invalid-feedback">
               Por favor introdusco un correo electrónico valido.
              </div>
            </div>
            <div class="col-12">
              <label for="email" class="form-label">Numero de contacto.(Opcional)</label>
              <input type="text" class="form-control" id="firstName" placeholder="9999999999" v-model.trim="Captura.numeroCon" v-on:keypress="isNumber($event)">
            </div>

            <div class="col-12">
              <label for="address" class="form-label">Dirección.(Opcional)</label>
              <input type="text" class="form-control" id="address" placeholder="Calle# por# y # Fracc. Col." v-model.trim="Captura.direccion" >
            </div>

            <div class="col-21">
              <label for="file">Subir una foto.</label> <br>
              <input type="file" id="file" ref="file" class="form-control-file" accept="image/*" @change="onChangeFileUpload" multiple>
              <div v-if="Captura.imagen.length">
                
                    <img class="previ" v-for="(cap,index) in Captura.imagen" :key="index"  :src="cap" />

              </div>
             
            </div>

            <hr class="my-4">
          </div>

          <h4 class="mb-3 d-flex justify-content-center">Localización de la colmena</h4>

          <div class="my-3">
            <div class="form-check d-flex justify-content-center p-2">
              <input id="AutoM" type="radio" class="form-check-input" value="Auto" v-on:click="Selecciona" v-model="Selecc" required>
              <label class="form-check-label" for="AutoM">Auto Localizacion</label>
            </div>
            <div class="form-check d-flex justify-content-center p-2">
              <input id="Mloca"  type="radio" class="form-check-input" value="Manual" v-on:click="Selecciona" v-model="Selecc" required>
              <label class="form-check-label" for="Mloca">Localizacion Manual</label>
            </div>   
          </div>
       <section v-if="Selecc=='Auto'">
            <div>
                <div class="row gx-5 d-flex justify-content-center">
                    <div class="col-lg-6  order-lg-1">
                        <div>
                            <div>
                                <p>Esto suele tardar unos segundos, si la posicion detectada no es la correcta usted puede mover el cursor.</p>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-ama" type="button" v-on:click="mapa" :disabled="botondisable">Generar</button>
                            </div>
                            <hr class="my-4">
                            
                            <div id="map"></div>
                                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="Selecc=='Manual'">
            <div >
                <div class="row gx-5 d-flex justify-content-center">
                    <div class="col-lg-6  order-lg-1">
                        <div>
                            <div>
                                <p>Esto suele tardar unos segundos, Mueva el cursos hasta una posicion aproximada de donde se encuentra.</p>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-ama" type="button" v-on:click="mapaEstatico" :disabled="botondisable">Generar</button>
                            </div>
                            <hr class="my-4">
                            
                            <div id="mapEs"></div>
  
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <hr class="my-4">
            <div class="d-flex justify-content-center">
            <button class="btn btn-ama" type="submit">Enviar</button>

            </div>
          
        </form>
      </div>  
    </div>
  </main>
</div>
</template>

<script>
import {mapActions} from 'vuex'
export default {

  data(){
   return{
    valida:false,
    myMarker:null,
    Selecc : null,
    map:null,
    botondisable:false,
    url: null,
    ima:null,
    lati:"",
    longi:"",
    Captura:{
      nombres:'',
      apellidos:'',
      correo:'',
      numeroCon:'',
      direccion:'',
      imagen: [],
      cordenas:"",
    },
   }
  },
  methods:{
     ...mapActions(['envioavistamiento']),
      Selecciona(){
        this.lati=null,
        this.longi=null,
        this.botondisable=false;
        this.myMarker=null;
        this.map=null;
        },
   async procesarformulario(){
      if(this.Captura.apellidos && this.Captura.nombres && this.Captura.correo) {
        this.$swal({
          icon: 'success',
          title: 'Enviando avistamiento espere un momento.'
        }); 
       if(this.Captura.cordenas === ''){
          this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Genere las coordenadas antes de enviar.',
        }); 
        return; 
       } 
      let res = await this.envioavistamiento(this.Captura);
      let data = await res.json();
      if(data.status == '400'){
        this.$swal({
          icon: 'error',
          title: 'Ah ocurrido un problema',
          text: data.msg ,
        }); 
        return ;
      }else if(data.status == '200'){
        this.clean_data();
        this.$swal({
            icon: 'success',
            title: 'Avistamiento enviado',
            text: 'Gracias por enviarnos el avistamiento agradecemos tu apoyo.',
          }); 

      }
      }else{
        this.valida=true
      }
      
    },
    onChangeFileUpload (e) {

      for(var i = 0; i < e.target.files.length; i++){
        const file = e.target.files[i];
        if(file.size > 10000000){
        this.Captura.imagen=[]     
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Solo puedes subir hasta 10MB',
        }); 
        return
      }
      }

      this.Captura.imagen = []

      if(this.$refs.file.files.length<=5){
        if(this.$refs.file.files.length==0){
          this.Captura.imagen = []
        }else{
          for (var i = 0; i < this.$refs.file.files.length; i++) {
               this.encodeImage(this.$refs.file.files[i])
          }
        }
      }else{
        
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Solo puede escoger cinco imagenes.',
        }); 
        document.getElementById("file").value = "";
        this.Captura.imagen=[]
      }
     
    },    
    encodeImage (input) {
      if (input) {
        const reader = new FileReader()
        reader.onload = (e) => {
         this.Captura.imagen.push(e.target.result)
        }
        reader.readAsDataURL(input)
      }
    }
    ,
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[a-zA-Z\s\u00C0-\u017F]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[0-9\s-]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },

        mapita(position) {
                let lat = position.coords.latitude;
                let long = position.coords.longitude;

                const startlat = lat.toFixed(8);
                const startlon = long.toFixed(8);
                
                this.Captura.cordenas= startlat+","+startlon
    
                var options = {
                center: [startlat, startlon],
                zoom: 16
                }
                this.map = L.map('map', options);
                
                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: 'OSM'}).addTo(this.map);

                this.myMarker = L.marker([startlat, startlon], {title: "Coordinates", alt: "Coordinates", draggable: true}).addTo(this.map).on('dragend',this.marcador);
            },
            marcador() {
                    var lat = this.myMarker.getLatLng().lat.toFixed(8);
                    var lon = this.myMarker.getLatLng().lng.toFixed(8);
                    var nzoom = 16;
                    var czoom = this.map.getZoom();
                    if(czoom < 18) { nzoom = czoom + 1.3; }
                    if(nzoom > 18) { nzoom = 18; }
                    if(czoom != 18) { this.map.setView([lat,lon], nzoom); } else { this.map.setView([lat,lon]); }

                    this.myMarker.bindPopup("Lat " + lat + "<br />Lon " + lon).openPopup();
                    this.lati= lat
                    this.longi=lon
                    this.Captura.cordenas= lat+","+lon
                },
        mapa(){
            this.botondisable=true;
            navigator.geolocation.getCurrentPosition(this.mapita);
            
        },
        mapaEstatico(){
                this.botondisable=true;
                let lat = 20.529763;
                let long = -88.780846;

                const startlat = lat.toFixed(8);
                const startlon = long.toFixed(8);
                
                this.Captura.cordenas= startlat+","+startlon
    
                var options = {
                center: [startlat, startlon],
                zoom: 8
                }
                 this.map = L.map('mapEs', options);  

                L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {attribution: 'OSM'}).addTo(this.map);

                this.myMarker = L.marker([startlat, startlon], {title: "Coordinates", alt: "Coordinates", draggable: true}).addTo(this.map).on('dragend',this.marcador);
        },
        clean_data(){
          this.Captura.nombres=''
          this.Captura.apellidos=''
          this.Captura.correo=''
          this.Captura.numeroCon=''
          this.Captura.direccion=''
          this.Captura.imagen= []
          this.Captura.cordenas=""
          this.Selecc=null 
          this.valida=false
        }
        
  },
  mounted(){
  }
}
</script>

<style scoped>
.previ{
  width: 125px;
  height: 120px;
  border: solid;
}
.pad{
    padding-left: 15%;
    padding-right: 15%;

}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
.btn-ama{
    background-color: #FDBC55;
    color: white;
}
#mapEs{
 width:100%;
 height:350px;
}
#map{
 width:100%;
 height:350px;
}
@media(max-width: 768px) {
#map{
    height: 250px;
    width: 100%; 
  }
#mapEs{
    height: 250px;
    width: 250px; 
  }
}
</style>