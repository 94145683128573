<template>
         <section class="ama pt-5 pb-5 d-flex justify-content-center w-100">
       </section>
        <h2 v-if="cargando">Cargando este proceso puede tomar algunos segundos.</h2>
        <h5 v-if="cargando">Espere a que desaparezca este mensaje.</h5>
        <h2 class="display-4 p-5">Mapa</h2>
        <div class="container">
            <div class="row pb-5">
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/Melipona.svg" alt="Marcador Melipona beecheii" />
                <h6 class="text-center"> Melipona beecheii</h6>
            </div>
            
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/pectoralis.svg" alt="Marcador Scaptotrigona pectoralis" />
                <h6 class="text-center"> Scaptotrigona pectoralis</h6>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/niitkib.svg" alt="Marcador Lestrimelitta niitkib" />
                <h6 class="text-center"> Lestrimelitta niitkib</h6>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/Partamona.svg" alt="Marcador Partamona bilienata" />
                <h6 class="text-center"> Partamona bilienata</h6>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/Nannotrigona.svg" alt="Marcador Nannotrigona perilampoides" />
                <h6 class="text-center"> Nannotrigona perilampoides</h6>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/nigra.svg" alt="Marcador Frieseomelitta nigra" />
                <h6 class="text-center"> Frieseomelitta nigra</h6>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/Apis.svg" alt="Marcador Apis mellifera" />
                <h6 class="text-center"> Apis mellifera</h6>
            </div>
            <div class="col d-flex align-items-center justify-content-center">
                <img class="marc" src="../assets/marcadores/Euglossa.svg" alt="Marcador Euglossa" />
                <h6 class="text-center"> Euglossa</h6>
            </div>
        </div>
        </div>


        <div class="container">
            <h5 v-if="cargando">Este proceso puede tomar algunos segundos.</h5>
            <h5 v-if="cargando">Espere a que desaparezca este mensaje.</h5>
            <div id="map">       
            </div>
        </div>
        
</template>

<script>
import {mapActions,mapState} from 'vuex'

export default {

    data(){
        return{
            cargando:true,
            marker:null,
            map:null,
            mapLink:null,
            cargando:true,
            Melipona: require('../assets/marcadores/Melipona.svg'),
            pectoralis: require('../assets/marcadores/pectoralis.svg'),
            niitkib: require('../assets/marcadores/niitkib.svg'),
            Partamona: require('../assets/marcadores/Partamona.svg'),
            Nannotrigona: require('../assets/marcadores/Nannotrigona.svg'),
            nigra: require('../assets/marcadores/nigra.svg'),
            Apis: require('../assets/marcadores/Apis.svg'),
            Euglossa: require('../assets/marcadores/Euglossa.svg'),
        }
    },
    methods:{
        ...mapActions(['listaavistamiento','cargarLocalStorage']),
        split(){
            let a = '20.95858380,-89.57868650'
            let b = a.split(',');
            console.log(b)
            console.log(b[0]+" latitud")
            console.log(b[1]+" longitud")
        },
        mapitamarcadores(){
            this.cargando=false;

            this.map = L.map('map').setView([20.529763, -88.780846], 8);
            
            this.mapLink =
            '<a href="http://openstreetmap.org">OpenStreetMap</a>';
            L.tileLayer(
            'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; ' + this.mapLink + ' Contributors',
                maxZoom: 18,
            }).addTo(this.map);

            for (var i = 0; i < this.avistamientos.length; i++){
                if(this.avistamientos[i].cordenas){
                    let cord= this.avistamientos[i].cordenas.split(',')
                    if(this.avistamientos[i].especie == 'Melipona beecheii'){
                        this.marcardorperso(this.Melipona,cord,this.avistamientos[i].especie)
                    }else if(this.avistamientos[i].especie == 'Scaptotrigona pectoralis'){
                        this.marcardorperso(this.pectoralis,cord,this.avistamientos[i].especie)
                    }
                    else if(this.avistamientos[i].especie == 'Lestrimelitta niitkib'){
                        this.marcardorperso(this.niitkib,cord,this.avistamientos[i].especie)
                    }else if(this.avistamientos[i].especie == 'Partamona bilienata'){
                        this.marcardorperso(this.Partamona,cord,this.avistamientos[i].especie)
                    }else if(this.avistamientos[i].especie == 'Nannotrigona perilampoides'){
                        this.marcardorperso(this.Nannotrigona,cord,this.avistamientos[i].especie)
                    }else if(this.avistamientos[i].especie == 'Frieseomelitta nigra'){
                        this.marcardorperso(this.nigra,cord,this.avistamientos[i].especie)
                    }else if(this.avistamientos[i].especie == 'Apis mellifera'){
                        this.marcardorperso(this.Apis,cord,this.avistamientos[i].especie)
                    }else if(this.avistamientos[i].especie == 'Euglossa'){
                        this.marcardorperso(this.Euglossa,cord,this.avistamientos[i].especie)
                    }
                }
            }
            // for (var i = 0; i < parques.length; i++) {
            // marker = new L.marker([parques[i][1], parques[i][2]], {icon: customIcon})
            //     .bindPopup(parques[i][0])
            //     .addTo(map);
            // }
        },
        marcardorperso(ruta,cord,espe){
                var customIcon = new L.Icon({
                    iconUrl: ruta,
                    iconSize: [25, 41],
                    iconAnchor: [25, 41],
                    popupAnchor:  [-12, -28]
                    });
            this.marker = new L.marker([cord[0], cord[1]],{icon: customIcon})
                .bindPopup(espe)
                .addTo(this.map);   
        }
        },
    computed:{
        ...mapState(['avistamientos']),
    },
    created(){
        this.cargarLocalStorage()
        this.listaavistamiento()
        setTimeout(() => {
            this.mapitamarcadores()
            this.cargando=false;
        }, 4000);
        
    },
    mounted(){
        //this.mapitamarcadores()

    }
    
    
}
</script>

<style scoped>
.marc{
    height: 50px;
    width: 50px;
}
#map { 
 width:100%;
 height:350px;
 margin-bottom: 5rem;
}
.ama{
    background: #FDBC55;
    height: 175px;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
  color:#6c757d;
}
</style>