
<template>
  <!-- this is background yellow for navbar -->
  <section class="ama pt-5 pb-5 d-flex justify-content-center w-100">
  </section>
  <h2 v-if="cargando">Cargando este proceso puede tomar algunos segundos.</h2>
  <h5 v-if="cargando">Espere a que desaparezca este mensaje.</h5>
  <div class="d-flex  justify-content-between align-items-center">
    <h2 class="display-4 p-5">Avistamientos</h2>
    <!-- <div class="me-5">
      <button type="button" class="btn btn-warning m-1" data-bs-toggle="modal" data-bs-target="#modalcreate">Subir
        avistamiento</button>
    </div> -->
  </div>
  <div class="overflow-auto">
    <table v-if="list" class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center" scope="col">Nombres</th>
          <th class="text-center" scope="col">Apellidos</th>
          <th class="text-center" scope="col">Correo</th>
          <th class="text-center" scope="col">numeroCon</th>
          <th class="text-center" scope="col">direccion</th>
          <th class="text-center" scope="col">coordenas</th>
          <th class="text-center" scope="col">Fotos</th>
          <th class="text-center" scope="col">especie</th>
          <th class="text-center" scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in avista" :key="item.id">
          <td class="text-center">{{ item.nombres }}</td>
          <td class="text-center">{{ item.apellidos }}</td>
          <td class="text-center">{{ item.correo }}</td>
          <td class="text-center">{{ item.numeroCon }}</td>
          <td class="text-center">{{ item.direccion }}</td>
          <td class="text-center">{{ item.cordenas }} </td>
          <td class="text-center"><button type="button" class="btn btn-success m-1" data-bs-toggle="modal"
              data-bs-target="#Modalimage" @click="imagesinmodal(item.images)">Fotos</button></td>
          <td class="text-center">
            <p v-if="item.especie !== null">{{ item.especie }}</p>
            <button v-if="item.especie === null" type="button" class="btn btn-warning m-1" data-bs-toggle="modal"
              data-bs-target="#modalespe" @click="especiemodal(item)">Escoger especie</button>
          </td>
          <td>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-danger m-1" data-bs-toggle="modal" data-bs-target="#ModalEliminar"
                @click="setelimi(item.id)">Eliminar</button>
              <button type="button" class="btn btn-warning m-1" data-bs-toggle="modal" data-bs-target="#modalespe"
                @click="especiemodal(item)">Cambiar especie</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- tabla skelton -->
    <table v-if="!list" class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center" scope="col">Nombres</th>
          <th class="text-center" scope="col">Apellidos</th>
          <th class="text-center" scope="col">Correo</th>
          <th class="text-center" scope="col">numeroCon</th>
          <th class="text-center" scope="col">direccion</th>
          <th class="text-center" scope="col">coordenas</th>
          <th class="text-center" scope="col">Fotos</th>
          <th class="text-center" scope="col">especie</th>
          <th class="text-center" scope="col">Acción</th>
        </tr>
      </thead>
      <tbody aria-hidden="true" class="placeholder-glow">
        <tr v-for="(item, index) in skeleton" :key="index">
          <td class="text-center "><span class="placeholder col-12"></span></td>
          <td class="text-center "><span class="placeholder col-12"></span></td>
          <td class="text-center "><span class="placeholder col-12"></span></td>
          <td class="text-center "><span class="placeholder col-12"></span></td>
          <td class="text-center "><span class="placeholder col-12"></span></td>
          <td class="text-center "><span class="placeholder col-12"></span></td>

          <td class="text-center">
            <a href="#" tabindex="-1" class="btn btn-success m-1  disabled placeholder col-12"></a>
          </td>
          <td class="text-center">
            <span class="placeholder col-12"></span>
          </td>
          <td>
            <div class="d-flex justify-content-center">
              <a href="#" tabindex="-1" class="btn btn-danger m-1  disabled placeholder col-6"></a>
              <a href="#" tabindex="-1" class="btn btn-warning m-1  disabled placeholder col-6"></a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- paginacion -->
  <nav v-if="list" aria-label="...">
    <ul class="pagination pagination-lg">
      <li class="page-item">
        <a class="page-link" @click="setCurrent(1)" href="#">inicio</a>
      </li>
      <template v-for="page in paginD">
        <li :class="{ 'active page-item': page == current }" class="page-item" @click="setCurrent(page)">
          <a :class="{ ' page-link active ': page == current }" class="page-link" href="#">{{ page }}</a>
        </li>
      </template>
      <li class="page-item">
        <a class="page-link" @click="setCurrent(total)" href="#">final</a>
      </li>
    </ul>
  </nav>
  <!-- modal especie-->
  <div class="modal fade" id="modalespe" tabindex="-1" aria-labelledby="modalespe" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
            aria-label="Close"></button></div>
        <h1 class="modal-title text-center" id="staticBackdropLabel">Lista de especies</h1>
        <div class="modal-body text-center pb-5">
          <div class="container">
            <div class="row justify-content-center">

              <div v-if="update !== null" class="col-lg-8">
                <!-- v-model="" -->
                <select class="form-select" aria-label="Default select example" v-model="especie">
                  <option value="Melipona beecheii">Melipona beecheii</option>
                  <option value="Scaptotrigona pectoralis">Scaptotrigona pectoralis</option>
                  <option value="Lestrimelitta niitkib">Lestrimelitta niitkib</option>
                  <option value="Partamona bilienata">Partamona bilienata</option>
                  <option value="Nannotrigona perilampoides">Nannotrigona perilampoides</option>
                  <option value="Frieseomelitta nigra">Frieseomelitta nigra</option>
                  <option value="Apis mellifera">Apis mellifera</option>
                  <option value="Euglossa">Euglossa</option>
                </select>
                <br>
                <div>Seleccionado: {{ especie }}</div><br>
                <button class="btn hov m-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                  <i class="fas fa-times fa-fw"></i>
                  Cerrar ventana
                </button>
                <button class="btn hov m-1" type="button" data-bs-dismiss="modal" aria-label="Close"
                  @click="procesarmodal()">
                  <i class="fas fa-retweet"></i>
                  actualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal crearAvista-->
  <div class="modal fade" id="modalcreate" tabindex="-1" aria-labelledby="modalcreate" data-bs-backdrop="static"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
            aria-label="Close"></button></div>
        <h1 class="modal-title text-center" id="staticBackdropLabel">Crear avistamiento</h1>
        <div class="modal-body text-center pb-5">
          <div class="container">
            <div class="row justify-content-center">

              <div class="col-lg-8">
                <form v-bind:class="{ 'needs-validation was-validated': valida, 'needs-validation': !valida, }"
                  @submit.prevent="procesarformulario()" novalidate>

                  <div class="row g-3">
                    <div class="col-sm-6">
                      <label for="firstName" class="form-label">Nombres</label>
                      <input type="text" class="form-control" id="firstName" placeholder=""
                        v-on:keypress="isLetter($event)" v-model.trim="Captura.nombres" required>
                      <div class="invalid-feedback">
                        Se requiere nombres.
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <label for="lastName" class="form-label">Apellidos</label>
                      <input type="text" class="form-control" id="lastName" placeholder=""
                        v-on:keypress="isLetter($event)" v-model.trim="Captura.apellidos" required>
                      <div class="invalid-feedback">
                        Se requiere apellidos.
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="email" class="form-label">Correo de contacto</label>
                      <input type="email" class="form-control" id="email" placeholder="uncorreo@gmail.com"
                        v-model.trim="Captura.correo" required>
                      <div class="invalid-feedback">
                        Por favor introdusco un correo electrónico valido.
                      </div>
                    </div>
                    <div class="col-12">
                      <label for="email" class="form-label">Numero de contacto.(Opcional)</label>
                      <input type="text" class="form-control" id="firstName" placeholder="9999999999"
                        v-model.trim="Captura.numeroCon" v-on:keypress="isNumber($event)">
                    </div>

                    <div class="col-12">
                      <label for="address" class="form-label">Dirección.(Opcional)</label>
                      <input type="text" class="form-control" id="address" placeholder="Calle# por# y # Fracc. Col."
                        v-model.trim="Captura.direccion">
                    </div>

                    <div class="col-21">
                      <label for="file">Subir una foto.</label> <br>
                      <input type="file" id="file" ref="file" class="form-control-file" accept="image/*"
                        @change="onChangeFileUpload" multiple>
                      <div v-if="Captura.imagen.length">

                        <img class="previ" v-for="(cap, index) in Captura.imagen" :key="index" :src="cap" />

                      </div>

                    </div>

                    <hr class="my-4">
                  </div>

                  <h4 class="mb-3 d-flex justify-content-center">Localización de la colmena</h4>

                  <div class="row my-3">
                    <div class="col-sm-6">
                      <label for="firstName" class="form-label">Latitud</label>
                      <input type="text" class="form-control" id="firstName" placeholder=""
                        v-on:keypress="isLetter($event)" v-model.trim="Captura.nombres" required>
                      <div class="invalid-feedback">
                        Se requiere nombres.
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <label for="lastName" class="form-label">Longitud</label>
                      <input type="text" class="form-control" id="lastName" placeholder=""
                        v-on:keypress="isLetter($event)" v-model.trim="Captura.apellidos" required>
                      <div class="invalid-feedback">
                        Se requiere apellidos.
                      </div>
                    </div>
                  </div>

                  <hr class="my-4">

                </form>
                <!-- v-model="" -->
                <button class="btn hov m-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                  <i class="fas fa-times fa-fw"></i>
                  Cerrar ventana
                </button>
                <button class="btn hov m-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                  <i class="fas fa-retweet"></i>
                  Crear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal images -->
  <div class="modal fade" id="Modalimage" tabindex="-1" aria-labelledby="Modalimage" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
            aria-label="Close"></button></div>
        <div class="modal-body text-center pb-5">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <img v-for="item in imagenesmodal" :key="item.id" class="img-fluid rounded mb-3" :src="item.imagen"
                  alt="..." />
                <button class="btn colorboton" type="button" data-bs-dismiss="modal" aria-label="Close">
                  <i class="fas fa-times fa-fw"></i>
                  Cerrar ventana
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal eliminar  -->
  <div class="modal fade" id="ModalEliminar" data-bs-backdrop="static" tabindex="-1" aria-labelledby="ModalEliminar"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0"><button class="btn-close" type="button" data-bs-dismiss="modal"
            aria-label="Close"></button></div>
        <div class="modal-body text-center pb-5">
          <div class="container">
            <div class="row justify-content-center">
              <h1>¿Esta seguro de eliminar?</h1>
              <div class="col-lg-8">
                <button class="btn btn-danger m-1" type="button" data-bs-dismiss="modal" aria-label="Close"
                  @click="Seguroeliminar()">
                  Eliminar.
                </button>
                <button class="btn btn-primary m-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                  Cerrar ventana.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- {{imagenes.length}} -->
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      cargando: true,
      list: false,
      modaselimiar: null,
      numShown: 3,
      total: null,
      current: 1,
      paginD: null,
      avista: [],
      skeleton: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
      imagenesmodal: [],
      especie: null,
      update: null,
      Captura:{
      nombres:'',
      apellidos:'',
      correo:'',
      numeroCon:'',
      direccion:'',
      imagen: [],
      cordenas:"",
    },
    }
  },
  methods: {
    ...mapActions(['listaavistamiento', 'cargarLocalStorage', 'eliminaravistamiento', 'actualizarespecie']),
    setelimi(idavis) {
      this.modaselimiar = idavis;
    },
    async Seguroeliminar() {
      let response = await this.eliminaravistamiento(this.modaselimiar);
      if (response.status == 200) {
        this.$swal({
          icon: 'success',
          title: 'Eliminar',
          text: 'Avistamientio eliminado.',
        });
        this.avista = this.avista.filter(item => item.id !== this.modaselimiar)
      }

    },
    setCurrent(page) {
      this.current = page
      this.pages();
      this.avistamientos_inpage(page);
    },
    pages() {
      const numShown = Math.min(this.numShown, this.total);
      let first = this.current - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.total - numShown + 1);
      //console.log(first)
      this.paginD = [...Array(numShown)].map((k, i) => i + first);
    },

    async avistamientos_inpage(page) {
      var SOneDateFrom = JSON.parse(localStorage.getItem('usuario'));
      // console.log(SOneDateFrom.access_token);
      this.list = false;
      // console.log(this.list);
      try {
        const res = await fetch(`http://127.0.0.1:8000/api/avistamientos?page=${page}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + SOneDateFrom.access_token
          }
        })
        const dataDB = await res.json()
        console.log(dataDB.data);
        if (dataDB.error) {
          if (dataDB.error == 'Usuario no autenticado.') {
            router.push('/login')
            localStorage.removeItem('usuario')
          }
          return
        }
        // console.log(dataDB.data.data);
        this.avista = dataDB.data.data;
        this.list = true;
        // console.log(this.list);

      } catch (error) {
        console.log(error)
      }
    },
    async procesarmodal() {
      this.update.especie = this.especie;
      let response = await this.actualizarespecie(this.update);
      if (response.status == 200) {
        this.$swal({
          icon: 'success',
          title: 'Especie',
          text: 'Especie actualizada.',
        });
        let index = this.avista.indexOf(this.update);
        this.avista[index].especie = this.especie;
        this.especie = null;
        this.update = null;
      }
    },
    imagesinmodal(images) {
      this.imagenesmodal = images
    },
    especiemodal(item) {
      this.especie = item.especie
      this.update = item;
    },
  },
  async created() {
    this.cargarLocalStorage()
    let data = await this.listaavistamiento()
    this.avista = data.data
    this.list = true;
    this.numShown = 3
    this.total = data.last_page
    this.current = 1
    this.pages()
    this.cargando = false;
  },


}
</script>

<style scoped>
.ama {
  background: #FDBC55;
  color: white;
  height: 175px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
  color: #6c757d;
}

.hov:hover {
  background-color: gray;
}</style>