<template>
         <section class="ama pt-5 pb-5 d-flex justify-content-center w-100">
       </section>
       <!-- {{analisis[9][0].Enero}} -->
       <!-- {{temp}} -->
        <h2 class="display-4 p-5">Análisis</h2>
        <h6 class="text-center">Buscar por año</h6>
        <div class="d-flex justify-content-center pb-5">
            <div class=" m-1">
            <select class="form-select" aria-label="" v-model="temp">
            <option selected>Año</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            </select>
            </div>
            <button class="btn btn-ama m-1" @click="obteneranio()">Buscar</button>
        </div>
        
        <h2 v-if="cargando">Cargando este proceso puede tomar algunos segundos.</h2>
        <h5 v-if="cargando">Espere a que desaparezca este mensaje.</h5>
        <h2 v-if="nulldata">No se encontraron datos para el año {{aenviar}}.</h2>
        <div class="contairner d-flex justify-content-center ">
            <div class="prue">
                <canvas id="planet-chart"></canvas>
            </div>
        </div>
        <div class="contairner d-flex justify-content-center ">
            <div class="prue">
                <canvas id="planet"></canvas>
            </div>
        </div>
        <div class="contairner d-flex justify-content-center ">
            <div class="prue">
                <canvas id="linea"></canvas>
            </div>
        </div>                  
       
</template>

<script>
import Chart from 'chart.js/auto';
import {mapActions,mapState } from 'vuex'
export default {

    data(){
        return{
            cargando:false,
            nulldata:false,
            temp:'Año',
            aenviar:null,
            pay:null,
            totales:null,
            lineatiempo:null,
            colores:[
                        'rgb(253, 188, 85, 0.2)',
                        'rgb(142, 68, 173, 0.2)',
                        'rgb(52, 152, 219, 0.2)',
                        'rgb(171, 178, 185, 0.2)',
                        'rgb(120, 66, 18, 0.2)',
                        'rgb(0, 0, 0, 0.2)',
                        'rgb(230, 126, 34, 0.2)',
                        'rgb(35, 155, 86, 0.2)'
            ],
            coloresborder:[
                        'rgb(253, 188, 85)',
                        'rgb(142, 68, 173)',
                        'rgb(52, 152, 219)',
                        'rgb(171, 178, 185)',
                        'rgb(120, 66, 18)',
                        'rgb(0, 0, 0)',
                        'rgb(230, 126, 34)',
                        'rgb(35, 155, 86)'
            ]
        }
    },
    methods:{
        ...mapActions(['moduloanalisis','cargarLocalStorage']),
        grafica(){
          const data = {
                labels: ['Melipona beecheii','Scaptotrigona pectoralis','Lestrimelitta niitkib','Partamona bilienata',
                'Nannotrigona perilampoides','Frieseomelitta nigra','Apis mellifera','Euglossa'],
                datasets: [{
                data: [this.analisis[0].porcentaje, this.analisis[1].porcentaje,this.analisis[2].porcentaje,
                this.analisis[3].porcentaje,this.analisis[4].porcentaje,this.analisis[5].porcentaje,
                this.analisis[6].porcentaje,this.analisis[7].porcentaje],
                    backgroundColor: [
                        'rgb(253, 188, 85, 0.7)',
                        'rgb(142, 68, 173, 0.7)',
                        'rgb(52, 152, 219, 0.7)',
                        'rgb(171, 178, 185, 0.7)',
                        'rgb(120, 66, 18, 0.7)',
                        'rgb(0, 0, 0, 0.7)',
                        'rgb(230, 126, 34, 0.7)',
                        'rgb(35, 155, 86, 0.7)'
                    ],
                hoverOffset: 15,
                }]
            };
            const ctx = document.getElementById('planet-chart');
            const exampleChart = new Chart(ctx, {
                type: 'pie',
                data: data,
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Porcentajes de especies de un total de '+this.analisis[8].total+' avistamiento confirmados.',
                                 font: {
                                    size: 18
                                }
                        },
                        legend: {
                            labels: {
                                font: {
                                    size: 18
                                }
                            }
                        }
                    }
                
            }
            });
            this.pay= exampleChart
        },
        barras(){
          const data = {
                labels: ['Melipona beecheii','Scaptotrigona pectoralis','Lestrimelitta niitkib','Partamona bilienata',
                'Nannotrigona perilampoides','Frieseomelitta nigra','Apis mellifera','Euglossa'],
                datasets: [{
                label: 'No. de especies',
                data: [this.analisis[0].Melipona, this.analisis[1].Scaptotrigona,this.analisis[2].Lestrimelitta,
                this.analisis[3].Partamona,this.analisis[4].Nannotrigona,this.analisis[5].Frieseomelitta,
                this.analisis[6].Apis,this.analisis[7].Euglossa],
                    backgroundColor: [
                        'rgb(253, 188, 85, 0.2)',
                        'rgb(142, 68, 173, 0.2)',
                        'rgb(52, 152, 219, 0.2)',
                        'rgb(171, 178, 185, 0.2)',
                        'rgb(120, 66, 18, 0.2)',
                        'rgb(0, 0, 0, 0.2)',
                        'rgb(230, 126, 34, 0.2)',
                        'rgb(35, 155, 86, 0.2)'
                    ],
                    borderColor: [
                        'rgb(253, 188, 85)',
                        'rgb(142, 68, 173)',
                        'rgb(52, 152, 219)',
                        'rgb(171, 178, 185)',
                        'rgb(120, 66, 18)',
                        'rgb(0, 0, 0)',
                        'rgb(230, 126, 34)',
                        'rgb(35, 155, 86)'
                    ],
                    borderWidth: 1
                }]
            };
            const ctx = document.getElementById('planet');
            const exampleChart = new Chart(ctx, {
                type: 'bar',
                data: data,
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Total de especies',
                            font: {
                                    size: 18
                            }
                        },
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    size: 18
                                }
                            }
                        }
                    }
                
            }
            });
            this.totales=exampleChart
        },
        lineas(){
            var lienameses=this.analisis[9]
            var colors= this.colores
            var colorsborder= this.coloresborder
            // analisis[9][0].especie
            // console.log("----------------")
            //  console.log(this.analisis[9])
            // console.log(lienameses[0])
            // console.log("----------------")
            let datatem=[]
             for (let i = 0; i < lienameses.length ; i++) {  
                // console.log("for--"+i)
                var tempdata={
                label: lienameses[i].especie,
                data: [lienameses[i].Enero,lienameses[i].Febrero,lienameses[i].Marzo,
                lienameses[i].Abril,lienameses[i].Mayo,lienameses[i].Junio,
                lienameses[i].Julio,lienameses[i].Agosto,lienameses[i].Septiembre,
                lienameses[i].Octubre,lienameses[i].Noviembre,lienameses[i].Diciembre],
                backgroundColor: colors[i],
                borderColor:colorsborder[i] ,                 
                fill: true,
                tension: 0.1
                } 
                // console.log(tempdata)           
                datatem.push(tempdata)       
             }
            // console.log(datatem)
          const data = {
                labels: ['Enero',"Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto",
                "Septiembre","Octubre","Noviembre","Diciembre"],
                datasets:datatem
            };
            const ctx = document.getElementById('linea');
            const exampleChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Avistamientos por mes',
                            font: {
                                size: 18
                            }
                        },
                    }
                
            }
            });
            this.lineatiempo= exampleChart
        },
        obteneranio(){
            this.nulldata=false;
            this.cargando=true,
            this.aenviar=this.temp;
            this.moduloanalisis(this.aenviar)
                if(this.pay && this.totales && this.lineatiempo){
                    this.pay.destroy()
                    this.totales.destroy()
                    this.lineatiempo.destroy()
                    this.pay=null
                    this.totales=null
                    this.lineatiempo=null
                }
            setTimeout(() => {
                if(this.analisis.mensaje!='Null avistamientos'){
                    this.grafica()
                    this.barras()
                    this.lineas()
                    this.cargando=false;
                    this.nulldata=false;
                }else{
                    this.nulldata=true
                    this.cargando=false;
                }
                
            }, 3000);
        },
        },
    computed:{
        ...mapState(['analisis']),
        
    },
    created(){


  
    },
    mounted(){
        //this.mapitamarcadores()
    
    }
    
    
}
</script>

<style scoped>
.prue{
  height: 70vh;
  width: 70vw;
  padding-bottom: 5rem ;
}
.ama{
    background: #FDBC55;
    height: 175px;
}
.btn-ama{
    background-color: #FDBC55;
    color: white;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;
  color:#6c757d;
}
</style>